import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import ContactBriefly from "../components/shared/contact-briefly/contact-briefly";
import BannerWithImg from "../components/shared/banner-with-img/banner-with-img";
import ServiceDesc from "../components/services/service-desc/service-desc";
import ServiceFeatures from "../components/services/service-features/service-features";
import ServiceTechnologies from "../components/services/service-technologies/service-technologies";

import mobileAppsBannerImg from "../images/banners-img/mobile-apps-banner-img-tiny.jpg"
import mobileappIcon from "../images/icons/mobile-app.svg"
import checkIcon from "../images/icons/check.svg"

import nativescriptLogo from "../images/technologies/nativescript.svg"
import awsLogo from "../images/technologies/aws.svg"
import mongoLogo from "../images/technologies/mongo.svg"
import nodejsLogo from "../images/technologies/nodejs.svg"
import ionicLogo from "../images/technologies/ionic.svg"
import flutterLogo from "../images/technologies/flutter.svg"
import firebaseLogo from "../images/technologies/firebase.svg"
import reactfirebaseLogo from "../images/technologies/reactfirebase.svg"


// import consultingIcon from "../images/icons/consulting.svg"

const features = [
	{ id: "cross-platform", title: "Desarrollo cross-platform", desc: "Desarrollo tanto para Android como para IOS.", iconSrc: checkIcon},
	{ id: "admin", title: "Módulo de administración", desc: "Total control del contenido y usuarios de la aplicación.", iconSrc: checkIcon},
	{ id: "security", title: "Seguridad", desc: "Manejo y protección de la información.", iconSrc: checkIcon},
	{ id: "cloud", title: "Soporte y Cloud", desc: "Soporte y servicios escalables de cloud.", iconSrc: checkIcon}
];

const technologies = [
	{ id: "nativescript", url:"", logoSrc: nativescriptLogo},
    { id: "aws", url:"", logoSrc: awsLogo},
	{ id: "mongo", url:"", logoSrc: mongoLogo},
	{ id: "nodejs", url:"", logoSrc: nodejsLogo},
	{ id: "ionic", url:"", logoSrc: ionicLogo},
	{ id: "flutter", url:"", logoSrc:flutterLogo},
    { id: "firebase", url:"", logoSrc: firebaseLogo},
    { id: "reactfirebase", url:"", logoSrc: reactfirebaseLogo},
];

const ServicesMobileAppsPage = () => {

    return (
        <Layout>
            <SEO title="Desarrollo de aplicaciones móviles" description="Cree productos y servicios más personalizados por medio de una aplicación móvil. Desarrollo móvil Costa Rica." lang="es" path="aplicaciones-moviles/" meta={[
                {
					property: `og:type`,
					content: `website`,
                },
                {
                    property: `og:image`,
                    content: 'https://res.cloudinary.com/spheredevs/image/upload/v1600464749/SphereDevs/mobile-apps-banner-img-tiny.jpg'
                },
                {
                    property: `og:locale`,
                    content: 'es_CR',
                },
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:title`,
					content: "Desarrollo de aplicaciones móviles",
				},
				{
					name: `twitter:description`,
					content: "Cree productos y servicios más personalizados por medio de una aplicación móvil. Desarrollo móvil Costa Rica.",
                },
                {
                    name: `twitter:creator`,
                    content: `@SphereDevs`
                },
                {
                    name: `twitter:site`,
                    content: `@SphereDevs`,
                }
            ]}/>
            <BannerWithImg urlImage="https://res.cloudinary.com/spheredevs/image/upload/v1600464749/SphereDevs/mobile-apps-banner-img-tiny.jpg" description="Desarrollo de aplicaciones móviles" />
            <ServiceDesc text="Mediante tecnologías móviles amplíe su gama de oportunidades para generar productos y servicios más personalizados. Desarrollamos aplicaciones móviles que acercan más a sus clientes con su negocio y generan valor con sus servicios." iconSrc={mobileappIcon}/>
            <ServiceFeatures title="Todos nuestos desarrollos cuentan con:" elements={features} />
            <ServiceTechnologies title="Desarrolle sus proyectos con lo mejor de la industria" elements={technologies} />
            <ContactBriefly description="¿Tienes algo en mente? No esperes más!" contactUsAction="/contacto" contactUsLabel="Iniciar proyecto" />
        </Layout>
    )
}

export default ServicesMobileAppsPage